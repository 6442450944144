import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

import NavBar from "./NavBar/NavBar";
import LocationView from "./LocationView/LocationView";

class App extends Component {
  constructor() {
    super();

    this.state = {
      isAuthenticated: false,
      user: null,
      activeLocationID: null,
      locations: null,
    };
  }

  componentDidMount() {
    // Init flow.
    this.loadSavedAuthToken();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isAuthenticated !== prevState.isAuthenticated) {
      if (this.state.isAuthenticated) {
        // Fetch available locations for this user.
        this.fetchLocations();
      }
    }
  }

  authenticateGoogleUser = async (googleUser) => {
    let res = (
      await axios.post("https://home.perimeter.app/api/googleLogin", {
        token: googleUser.token,
      })
    ).data;
    return res && !!res.email ? res : null;
  };

  parseUserInfo = (authToken) => {
    try {
      return jwtDecode(authToken);
      //return wt_decode(authToken);
    } catch (err) {
      return null;
    }
  };

  loadSavedAuthToken = () => {
    let authToken = Cookies.get("authToken");
    if (authToken) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
      let user = this.parseUserInfo(authToken);
      this.setState({
        isAuthenticated: !!user,
        user: user,
      });
    }
  };

  updateAuthToken = (authToken) => {
    // TODO: Sanitize |authToken|.
    Cookies.set("authToken", authToken, { expires: 365 });
    axios.defaults.headers.common["Authorization"] = "Bearer " + authToken;
  };

  onUserChanged = async (googleUser) => {
    let user = null;

    if (googleUser) {
      let u = await this.authenticateGoogleUser(googleUser);
      if (u.authToken) {
        this.updateAuthToken(u.authToken);
        user = this.parseUserInfo(u.authToken);
      }
    } else {
      this.updateAuthToken("");
    }

    this.setState({
      isAuthenticated: !!user,
      user: user,
    });
  };

  async fetchLocations() {
    let locations = null;
    let res = (await axios.get(`https://home.perimeter.app/api/locations`))
      .data;
    if (res.locations) {
      locations = res.locations;
    }

    this.setState({
      activeLocationID:
        locations && !this.state.activeLocationID
          ? locations[0]
          : this.state.activeLocationID,
      locations: locations,
    });
  }

  handleLocationChange = (locationID) => {
    if (this.state.activeLocationID !== locationID) {
      this.setState({
        activeLocationID: locationID,
      });
    }
  };

  render() {
    return (
      <div>
        <NavBar
          onUserChanged={this.onUserChanged}
          onLocationIDChanged={this.handleLocationChange}
          isAuthenticated={this.state.isAuthenticated}
          activeLocationID={this.state.activeLocationID}
          locations={this.state.locations}
        />
        {this.state.isAuthenticated && (
          <LocationView locationID={this.state.activeLocationID} />
          /*
          <Routes>
            <Route
              exact
              path="/"
              render={() => (
                <LocationView locationID={this.state.activeLocationID} />
              )}
            />
          </Routes>
          */
        )}
      </div>
    );
  }
}

export default App;
