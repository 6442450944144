import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import jwtDecode from "jwt-decode";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectCenter: {
    flexGrow: 1,
    maxWidth: 160,
    centerTitle: true,
    color: "white",
  },
  button: {
    marginLeft: 10,
  },
});

class NavBar extends Component {
  logout = () => {
    googleLogout();
    this.props.onUserChanged(null);
  };

  onSuccess = (credentialResponse) => {
    //console.log(credentialResponse);
    let decodedCredentialJwt = jwtDecode(credentialResponse.credential);
    let user = {
      name: decodedCredentialJwt.name,
      avatarURL: decodedCredentialJwt.picture,
      email: decodedCredentialJwt.email,
      token: credentialResponse.credential,
    };

    this.props.onUserChanged(user);
  };

  googleResponse = (e) => console.log(e);

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Perimeter
            </Typography>
            {this.props.isAuthenticated && (
              <Select
                variant="outlined"
                value={this.props.activeLocationID || ""}
                onChange={(event) =>
                  this.props.onLocationIDChanged(event.target.value)
                }
                className={classes.selectCenter}
                classes={{ root: classes.selectRoot }}
              >
                {this.props.locations &&
                  this.props.locations.map((locationID, idx) => {
                    return (
                      <MenuItem value={locationID} key={idx}>
                        {locationID}
                      </MenuItem>
                    );
                  })}
              </Select>
            )}
            {!this.props.isAuthenticated && (
              <GoogleLogin onSuccess={this.onSuccess} />
            )}
            {this.props.isAuthenticated && (
              <Button
                color="inherit"
                className={classes.button}
                onClick={this.logout}
              >
                Sign Out
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Toolbar />
      </div>
    );
  }
}

export default withStyles(useStyles)(NavBar);
