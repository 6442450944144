import React, {Component} from 'react';
import axios from 'axios';

class CameraView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: null,
    };
  }

  componentDidMount() {
    this.fetchImage(this.props.locationID, this.props.cameraID);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.locationID !== prevProps.locationID || this.props.cameraID !== prevProps.cameraID) {
      this.fetchImage(this.props.locationID, this.props.cameraID);
    }
  }

  async fetchImage(locationID, cameraID) {
    let image = null;
    if (locationID && cameraID) {
      let res = (await axios.get(`https://home.perimeter.app/api/camera/${locationID}/${cameraID}`)).data;
      if (res.image) {
        image = res.image;
      }
    }
    this.setState({
      image: image,
    });
  }

  render() {
    const {image} = this.state;
    if (image === null) return <p>Loading ...</p>;
    return (
      <img src={this.state.image} width='100%' alt='camera img' />
    )
  }
}

export default CameraView;