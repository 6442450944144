import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

ReactDOM.render(
  // Google OAuth ClientID options:
  // * Local = 293427283024-3isvjvjul8k6kclnmvhal0nprns6mef5.apps.googleusercontent.com
  // * Production = 293427283024-1aqq3023on5h028khq4vmd0q49go0jbs.apps.googleusercontent.com
  <GoogleOAuthProvider clientId="293427283024-1aqq3023on5h028khq4vmd0q49go0jbs.apps.googleusercontent.com">
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
