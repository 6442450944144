import React, { Component } from "react";
import axios from "axios";
import { withStyles } from "@mui/styles";
import { Container, Card, CardContent } from "@mui/material";
import CameraView from "../CameraView/CameraView";

const useStyles = (theme) => ({
  container: {
    padding: 0,
  },
  card: {
    minWidth: 275,
    padding: 0,
  },
  cardContent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
});

class LocationView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locationID: null,
      cameras: null,
    };
  }

  componentDidMount() {
    this.fetchCameras(this.props.locationID);
  }

  componentDidUpdate(prevProps) {
    if (this.props.locationID !== prevProps.locationID) {
      this.fetchCameras(this.props.locationID);
    }
  }

  async fetchCameras(locationID) {
    let cameras = null;
    if (locationID) {
      let res = (
        await axios.get(`https://home.perimeter.app/api/cameras/${locationID}`)
      ).data;
      if (res.cameras) {
        cameras = res.cameras;
      }
    }
    this.setState({
      locationID: locationID,
      cameras: cameras,
    });
  }

  render() {
    const { classes } = this.props;
    const { cameras } = this.state;

    if (cameras === null) return <p>Loading cameras ...</p>;
    return (
      <Container className={classes.container}>
        {cameras.map((cameraID, idx) => {
          return (
            <Card className={classes.card} key={idx}>
              <CameraView
                locationID={this.state.locationID}
                cameraID={cameraID}
              />
            </Card>
          );
        })}
      </Container>
    );
  }
}

export default withStyles(useStyles)(LocationView);
